/* ===================== HEADER ======================*/
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

.x-logo {
  align-items: center;
  margin: auto;
  width: 27px;
  height: 27px;
  transition: transform 0.5s ease;
}

.bg-ico:hover .x-logo {
  transform: scale(0.3);
}

.bg-ico:hover .social {
  transform: scale(1.3);
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
  transition: all ease 0.5s;
}

.site {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapper {
  padding-left: 30px;
  display: flex;
  height: 4vh;
  flex-direction: row;
  padding: 0 0px;
  align-items: center;
  justify-content: center;
}
.wrapper-footer {
  padding-left: 30px;
  display: flex;
  height: 8vh;
  flex-direction: row;
  padding: 0 0px;
  align-items: center;
  justify-content: start;
  margin-top: 16px;
  margin-left: -7px;
}

.fab {
  margin: auto;
}
.social {
  font-size: 1.4em;
  color: #fff;
  transition: all 0.35s;
  transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
}
.social:hover {
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
  transition: all ease 0.5s;
  -moz-transition: all ease-in 0.5s;
  -webkit-transition: all ease-in 0.5s;
  -o-transition: all ease-in 0.5s;
}

.facebook {
  color: #4267b2;
}

.twitter {
  color: #1da1f2;
}
.youtube {
  color: #c4302b;
}
.pinterest {
  color: #c8232c;
}
.instagram {
  color: transparent;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
  -webkit-background-clip: text;
}
.tumblr {
  color: #34526f;
}
.whatsapp {
  color: #25d366;
}

.bg-ico {
  display: flex;
  background-color: #fff;
  width: 50px;
  height: 50px;
  line-height: 60px;
  margin: 0 5px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 28%;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  opacity: 0.99;
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}
.bg-ico:hover {
  box-shadow: 0 9px 15px -5px rgba(0, 0, 0, 0.8);
}

#facebook:hover {
  background-color: #4267b2;
}

#twitter:hover {
  background-color: #000000;
}
#youtube:hover {
  background-color: #c4302b;
}
#pinterest:hover {
  background-color: #c8232c;
}
#instagram:hover {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}
#tumblr:hover {
  background-color: #34526f;
}
#whatsapp:hover {
  background-color: #25d366;
}

.facebook:hover,
.twitter:hover,
.youtube:hover,
.pinterest:hover,
.instagram:hover,
.tumblr:hover,
.whatsapp:hover {
  color: #fff;
  transform: scale(1.3);
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  color: #ffffff;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.social-icons a.whatsapp {
  background-color: #25d366;
}

.social-icons a.whatsapp:hover {
  background-color: #1da851;
}

.social-icons a.youtube {
  background-color: #ff0000;
}

.social-icons a.youtube:hover {
  color: red;
  background-color: white;
}

.social-icons a.facebook {
  background-color: #1877f2;
}

.social-icons a.facebook:hover {
  background-color: white;
  color: #1877f2;
}

.social-icons a.instagram {
  background-color: #e1306c;
  background: radial-gradient(
    circle at 30% 30%,
    #fdf497 0%,
    #fdf497 15%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}

.social-icons a.instagram:hover {
  filter: brightness(1.2);
}

.social-icons a i {
  line-height: 1;
}

.header {
  z-index: 99;
  width: 100%;
  padding-right: 5%;
}
@media (max-width: 768px) {
  .wrapper {
    display: none;
  }
  .header-action {
    display: none;
  }
  .wrapper-footer {
    margin-top: 1px;
    margin-left: -7px;
  }
}

.social-icons-new {
  color: white;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #73ff00;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
}
.social-icons-new {
  display: flex;
  gap: 15px;
}

.social-icons-new a:hover {
  background-color: #d3b66d;
  transform: scale(1.1);
}

.social-icons-new a i {
  line-height: 1;
}

.header:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: #fff;
  content: "";
  z-index: -1;
}

.header:before {
  padding: 10px;
  border-color: #ffffff transparent;
  border-style: solid;
  border-width: 0 60px 100px;
  content: "";
  right: -700px;
  position: absolute;
  width: 1150px;
  z-index: 1;
}

.header .container {
  margin-left: 0;
}

.header .col-lg-12 {
  padding-left: 0;
}

.header-inn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.site-logo a {
  background: #fff;
  width: 340px;
  display: inline-block;
}
.site-logo img {
  width: 340px;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
}

.header-navigation {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  z-index: 11;
}

.mainmenu ul {
  padding-left: 50px;
}

.mainmenu ul li {
  display: inline-block;
  list-style: none;
  margin-right: 20px;
  position: relative;
}

.mainmenu ul li a {
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  display: block;
  padding: 38px 0;
  position: relative;
  background: -webkit-linear-gradient(left, #b78d65, #b78d65 50%, #000 50%);
  background: linear-gradient(to right, #b78d65, #b78d65 50%, #000 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
}

.mainmenu ul > li.active a:before {
  padding: 30px;
  position: absolute;
  content: "";
  background: #b78d65;
  left: 50%;
  bottom: 25px;
  width: 25px;
  height: 3px;
  border-radius: 5px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.mainmenu ul li a:hover {
  background-position: 0 100%;
  border-color: #b78d65;
}

.mainmenu ul > li.active > a {
  background-position: 0 100%;
  border-color: #b78d65;
}

.mainmenu ul li ul {
  margin: -24px;
  left: 23px;
  position: absolute;
  top: 120%;
  width: 200px;
  background: #fff;
  padding: 0;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.mainmenu ul li:hover ul {
  visibility: visible;
  top: 100%;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.mainmenu ul li ul li {
  margin: 0;
  display: block;
  border-top: 1px solid #eee;
}

.mainmenu ul li ul li a {
  padding: 30px;

  display: block;
  padding: 14px 20px;
  text-transform: capitalize;
  font-size: 15px;
}

.header-btn {
  display: inline-block;
  padding: 16px 25px 16px 40px;
  background: #fff none repeat scroll 0 0;
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 500;
  margin-left: 35px;
  position: relative;
  color: #b78d65;
  margin-right: 20px;
}

.header-btn > div {
  position: absolute;
  left: -16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: #b78d65;
  text-align: center;
  color: #b78d65;
  border-radius: 5px;
  line-height: 40px;
  z-index: 1;
}

.header-btn > div i {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 8px;
  border-radius: 5px;
}

.header-btn > div:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid #b78d65;
  z-index: -1;
  border-radius: 5px;
}

.header-btn a:hover i.fab.fa-whatsapp {
  color: #25d366;
}

.header-btn a:hover i.fab.fa-youtube {
  color: #ff0000;
}

.header-btn a:hover i.fab.fa-facebook-f {
  color: #4267b2;
}

.header-btn a:hover i.fab.fa-instagram {
  color: #e1306c;
}

.search-toggle {
  color: #fff;
  padding: 10px;
}

.search-toggle:hover {
  color: #000;
}

#search-overlay {
  -webkit-transition: opacity 600ms, visibility 600ms;
  transition: opacity 600ms, visibility 600ms;
  opacity: 0;
  visibility: hidden;
}

.block {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  text-align: center;
  background: rgba(0, 0, 0, 0.9);
  margin: 0;
  z-index: 99;
}

.block:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.centered {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  padding: 10px 15px;
  color: #fff;
  border: none;
  background: transparent;
}

#search-box {
  position: relative;
  width: 100%;
  margin: 0;
}

#search-form {
  height: 4em;
  border-bottom: 1px solid #fff;
  background-color: transparent;
  overflow: hidden;
}

#search-box input[type="text"] {
  width: 100%;
  padding: 20px;
  color: #ffffff;
  outline: none;
  font-size: 20px;
  background: transparent;
}

#search-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: auto;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 42px;
  border-width: 0;
  background-color: transparent;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
}

#close-btn {
  position: fixed;
  top: 20px;
  right: 20px;
  color: #fff;
}

.spinner-master {
  display: none;
}

.spinner-spin {
  text-indent: -99999px;
}

.spinner-spin {
  width: 22px;
}
#search-overlay.block.show {
  opacity: 1;
  visibility: visible;
}
.responsiveMenu {
  display: none;
  width: 40px;
  height: 35px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .responsiveMenu {
    display: block;
  }
  .spinner-master {
    display: flex;
  }
  .header:before {
    display: none;
  }
  .header:after {
    display: none;
  }
  .header-btn {
    display: none;
  }
  .header-navigation {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .site-logo a {
    width: 250px;
  }
  .header {
    padding-right: 5px;
  }
  .header-navigation {
    position: static;
  }
  .centered {
    width: 80%;
  }
  #search-box input[type="text"] {
    font-size: 16px;
  }
  .block {
    z-index: 999999;
  }
  .mainmenu {
    display: none;
    padding: 30px;
  }
}

@media (width: 1024px) {
  .mainmenu ul {
    padding-left: 0;
  }
  .mainmenu ul li {
    margin-right: 15px;
  }
  .mainmenu ul li a {
    font-size: 14px;
  }
}

@media (min-width: 1600px) {
  .header-navigation {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1 1;
    position: relative;
    z-index: 11;
  }
  .header-btn {
    margin-right: -138px;
  }
}

@media (min-width: 1400px) {
  .header-action {
    margin-left: auto;
  }
  .mainmenu {
    margin: auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .header {
    z-index: 99;
    width: 100%;
    padding-right: 5%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .header {
    z-index: 99;
    width: 100%;
    padding-right: 1%;
  }
}
