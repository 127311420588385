.floating-icons-container {
  position: fixed;
  bottom: 20px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1000;
}

.floating-icons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.floating-icons.expanded {
  opacity: 1;
  visibility: visible;
}

.floating-icons .icon {
  position: relative;
  font-size: 26px;
  width: 50px;
  height: 50px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.floating-icons .icon.phone {
  background-color: #007bff;
  font-size: 15px;
}

.floating-icons .icon:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.floating-icons .icon:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: 50%;
  left: 110%;
  transform: translateY(-50%);
  background-color: #c1933c;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.floating-icons .icon:hover::after {
  opacity: 1;
}

.toggle-arrow {
  width: 50px;
  height: 50px;
  background-color: #c1933c;
  color: rgb(77 72 72);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 26px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.toggle-arrow:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.toggle-arrow.rotated {
  transform: rotate(180deg);
}
