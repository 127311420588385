.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: #c1933c;
  text-align: center;
  z-index: 9999;
}

.loader-text {
  display: inline-block;
  margin-top: 20px;
}
