.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgb(77 72 72);
    color: white;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 1000;
  }
  
  .scroll-to-top:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .scroll-to-top svg {
    font-size: 24px;
    stroke-width: 1px;
  }
  