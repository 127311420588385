.amenities-card {
  background-color: #234f3d;
  border-radius: 30px;
  width: 441.5px;
  padding: 32px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: 8rem;
}

.card-header {
  background-color: #d09a41;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 0;
  border-radius: 20px;
  margin-bottom: 15px;
}

.amenities-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.amenities-list li {
  font-weight: bold;
  color: #ffffff;
  font-size: 17px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  transition: color 0.4s ease;
}

.arrow-icon {
  margin-right: 8px;
}

.amenities-list li i {
  color: #ffffff;
  font-size: 14px;
}

.amenities-list hr {
  color: white;
}

.amenities-list li:hover {
  color: #c6993e;
}

.arrowright-icon {
  padding: 5px;
  color: #3b3832;
  margin-right: 10px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
}

.eco-nest-card {
  background-color: #234f3d;
  border-radius: 30px;
  width: 441.5px;
  padding: 32px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: 5rem;
}

@media (max-width: 575px) {
  .tour-area .container:before {
    top: 90px;
  }

  .tour_video_waves {
    position: absolute;
    left: 40%;
    top: 33%;
  }

  .tour-container {
    width: 201%;
  }

  .amenities-card {
    margin-left: -13px;
    width: 107%;
    padding: 20px;
    margin-top: 2rem;
  }
  .eco-nest-card {
    margin-left: -13px;
    width: 107%;
    padding: 20px;
    margin-top: 2rem;
  }
}
