.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-with-logo {
  background-color: rgba(255, 255, 255, 0.8);
}

.loader-default-bg {
  background-color: rgba(255, 255, 255, 0.8);
}

.spinner-with-logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-logo {
  width: 60px;
  height: auto;
  z-index: 2;
}

.Oval {
  z-index: 1;
}
