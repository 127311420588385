.journey-section {
  text-align: center;
  padding: 50px;
  background-color: #f8fbf7;
}

.journey-section h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
  color: #0a3d24;
  margin-bottom: 10px;
}

.intro-text {
  font-size: 1.1rem;
  color: #6b7c7a;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.6;
}

.journey-container {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.journey-card {
  border: 2px dotted #d3d0d0;
  background-color: #f8fbf7;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  align-items: center;
  width: 30%;
  min-width: 300px;
  position: relative;
  text-align: left;
}

.icon-wrapper {
  background-color: #c1933c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  margin-right: 20px;
  box-sizing: border-box;
  padding: 10px;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
}

.icon-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  filter: brightness(0) invert(1);
}

.res-icon {
  background-color: #c1933c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94%;
  height: 29%;
  margin-right: 20px;
  box-sizing: border-box;
  padding: 10px;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
}

.res-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  filter: brightness(0) invert(1);
}

.text-wrapper h3 {
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: underline;
  font-size: 1.2rem;
  color: #0a3d24;
  margin-bottom: 13px;
}

.text-wrapper p {
  color: #6b7c7a;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}

@media (max-width: 768px) {
  .journey-container {
    flex-direction: column;
    align-items: center;
  }

  .text-wrapper {
    margin-top: 17px;
    text-align: center;
  }

  .journey-card {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    display: block;
  }

  .journey-card {
    flex-direction: column;
    align-items: center;
  }

  .icon-wrapper,
  .res-icon {
    align-items: center;
    margin: auto;
    width: 80px;
    height: 80px;
  }
  .journey-card:nth-child(3) {
    margin: 0 auto;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .res-icon {
    width: 282px;
    height: 58px;
  }
  .journey-container {
    flex-direction: column;
    align-items: center;
  }
  .text-wrapper {
    margin-top: 17px;
    text-align: center;
  }
  .journey-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .icon-wrapper,
  .res-icon {
    align-items: center;
    margin: auto;
    width: 80px;
    height: 80px;
  }
  .journey-card {
    flex-direction: column;
    align-items: center;
  }
}

.underline-text:after {
  content: "";
  display: block;
  border-bottom: 2px solid;
  margin-top: 0px;
}
.underline-text {
  display: inline;
  position: relative;
}

.underline-text:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 2px;
  background-color: currentColor;
  display: inline-block;
  width: 100%;
}
