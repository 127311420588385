.projects-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
}

.projects-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.1);
  width: 70%;
}

.project-item {
  display: flex;
  align-items: center;
  flex: 1;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
}

.project-item img {
  height: 60px;
  margin-right: 20px;
}

.project-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.project-info h3 {
  font-size: 15px;
  color: #0d413f;
  margin-bottom: 5px;
}

.project-info .item-divider {
  border-bottom: 1px dotted #729f28;
  width: 90px;
  margin-bottom: 5px;
}
.project-info p {
  font-size: 1rem;
  color: #6c757d;
}

.divider {
  border-bottom: 1px dotted #6c757d;
  width: 1px;
  height: 50px;
  background-color: #e0e0e0;
  margin: 0 20px;
}

.info-title {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 1.2rem;
  color: #0d413f;
  margin-bottom: 5px;
}

@media (max-width: 430px) {
  .projects-box {
    flex-direction: column;
    align-items: center;
    width: 92%;
  }

  .divider {
    display: none;
  }

  .project-item {
    margin-bottom: 20px;
    padding: 10px 0;
  }
  .project-item img {
    height: 70px;
  }
  .project-item img {
    height: 70px;
  }
}

@media (min-width: 431px) and (max-width: 768px) {
  .projects-box {
    flex-direction: column;
    align-items: center;
    width: 60%;
  }

  .divider {
    display: none;
  }

  .project-item {
    margin-bottom: 20px;
    padding: 10px 0;
  }
  .project-item img {
    height: 70px;
  }
  .project-item img {
    height: 70px;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .projects-box {
    flex-direction: column;
    align-items: center;
    width: 60%;
  }

  .divider {
    display: none;
  }

  .project-item {
    margin-bottom: 20px;
    padding: 10px 0;
  }
  .project-item img {
    height: 70px;
  }
  .project-item img {
    height: 70px;
  }
}
